import React, { Component } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link } from "react-router-dom";
import Image from "./Image";
import ic_phone from "../icons/ic_phone.svg";
import ic_mail from "../icons/ic_mail.svg";
import ic_ig from "../icons/ic_ig.svg";
import ic_fb from "../icons/ic_fb.svg";
import logo from "../logo.svg";
import moment from "moment";
import ic_phone_white from "../icons/ic_phone_white.svg";
import Hamburger from "./Hamburger";

const LinkComponent = props => {
  if (props.match.path !== "/") {
    return <Link {...props} to={`/${props.href}`} />;
  }

  return <AnchorLink {...props} />;
};

export class Header extends Component {
  getOpeningTimes = () => {
    const openingTimes = {
      montag: { opens: "08:00", closes: "24:00" },
      dienstag: { opens: "08:00", closes: "24:00" },
      mittwoch: { opens: "08:00", closes: "24:00" },
      donnerstag: { opens: "08:00", closes: "24:00" },
      freitag: { opens: "08:00", closes: "24:00" },
      samstag: { opens: "08:00", closes: "24:00" },
      sonntag: { opens: "09:00", closes: "20:00" }
    };

    const status = {
      openingTimes: openingTimes,
      today: null,
      open: false,
      didOpen: false,
      didClose: false,
      opensAt: null,
      closesAt: null,
      opensIn: "",
      closesIn: ""
    };

    const now = moment();
    const today = now.format("dddd").toLocaleLowerCase();

    status.today = openingTimes[today];

    status.opensAt = moment(status.today.opens, "HH:mm");
    status.closesAt = moment(status.today.closes, "HH:mm");

    status.didOpen = now.diff(status.opensAt, "minutes") > 0;
    status.didClose = now.diff(status.closesAt, "minutes") > 0;

    if (status.didOpen && !status.didClose) {
      status.open = true;
      status.opensIn = moment
        .duration(now.diff(status.opensAt.add(1, "day")))
        .humanize();
      status.closesIn = moment.duration(now.diff(status.closesAt)).humanize();
    } else {
      status.opensIn = moment.duration(now.diff(status.opensAt)).humanize();
    }

    return status;
  };

  renderOpeningTimesDesktop = () => {
    const status = this.getOpeningTimes();

    if (status.didOpen && !status.didClose) {
      return (
        <li>
          <b>Heute</b><br/>bis {status.today.closes} offen!
        </li>
      );
    }

    return null;
  };

  renderOpeningTimesMobile = () => {
    const status = this.getOpeningTimes();

    let text = "";

    if (status.didOpen && !status.didClose) {
      text = `Wir haben heute bis ${
        status.today.closes
      } geöffnet, schau doch vorbei!`;
    } else {
      text = `Wir öffnen in ${status.opensIn} (${status.opensAt.format(
        "HH:mm"
      )}), schau doch vorbei!`;
    }

    return (
      <div className="openingTimes is-hidden-desktop">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="tel:06769706555"
          className="openingTimes-Link openingTimes-Call"
        >
          <Image src={ic_phone_white} alt="Anrufen" />
          Anrufen
        </a>
        <a
          href="https://goo.gl/maps/UpQCymuWCq92"
          target="_blank"
          rel="noopener noreferrer"
          className="openingTimesLine"
        >
          {text}
        </a>
        <Hamburger />
      </div>
    );
  };

  render() {
    return (
      <div className="headerWrapper">
        {this.renderOpeningTimesMobile()}

        <header className={`navbar is-hidden-mobile`}>
          <div className="textBox left">
            <ul className="links">
              <li>
                <br/>
                <LinkComponent
                  match={this.props.match}
                  offset={100}
                  href="#cafe"
                  title="Das Cafe"
                >
                  Das <b>Cafe</b>
                </LinkComponent>
              </li>
              <li>
                <br/>
                <LinkComponent
                  match={this.props.match}
                  offset={100}
                  href="#bar"
                  title="Die Bar"
                >
                  Die <b>Bar</b>
                </LinkComponent>
              </li>
            </ul>
            <div className="line" />
            <ul className="icons">
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="tel:06769706555"
                  title="Jetzt gleich anrufen!"
                >
                  <Image
                    src={ic_phone}
                    alt="Anrufen"
                    title="Jetzt gleich anrufen!"
                  />
                </a>
                <br/>&nbsp;
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:info@schaeffners.at"
                  title="Schreib uns doch!"
                >
                  <Image
                    src={ic_mail}
                    alt="E-Mail schreiben"
                    title="Schreib uns doch!"
                  />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/schaeffners/"
                  title="Folge uns auf Instagram!"
                >
                  <Image
                    src={ic_ig}
                    alt="Instagram"
                    title="Folge uns auf Instagram!"
                  />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/schaeffners/"
                  title="Folge uns auf Facebook!"
                >
                  <Image
                    src={ic_fb}
                    alt="Facebook"
                    title="Folge uns auf Facebook!"
                  />
                </a>
              </li>
            </ul>
          </div>
          <a href="/">
            <Image
              src={logo}
              alt="Logo vom Schäffners"
              className="logo is-hidden-mobile"
            />
          </a>
          <div className="textBox right">
            <ul className="links">
              <li>
                <br/>
                <LinkComponent
                  match={this.props.match}
                  offset={100}
                  href="#eatanddrink"
                  title="eat and drink"
                >
                  <b>Eat</b> & <b>Drink</b>
                </LinkComponent>
              </li>
              <li>
                <br/>
                <LinkComponent
                  match={this.props.match}
                  offset={100}
                  href="#kontakt"
                  title="Lage & Kontakt"
                >
                  Lage & <b>Kontakt</b>
                </LinkComponent>
              </li>
            </ul>
            <div className="line" />
            <ul className="icons times">
              <li>
                <b>Mo-Sa</b><br/>08:00-24:00
              </li>
              <li>
                <b>So</b><br/>09:00-20:00
              </li>
              {this.renderOpeningTimesDesktop()}
            </ul>
          </div>
        </header>
      </div>
    );
  }
}

export default Header;
