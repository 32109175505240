import React from "react";
import logo from "../logo_weiss.svg";
//import AnchorLink from "react-anchor-link-smooth-scroll";
// import {Link} from "react-router-dom";

// const LinkComponent = props => {
//     if (props.match.path !== "/") {
//         return <Link {...props} to={`/${props.href}`} />;
//     }
//
//     return <AnchorLink {...props} />;
// };

const Menu = ({ close }) => (
  <div className="menu hamburgermenu">
    <ul>
      <li>
        <a href="/#cafe" offset={100} onClick={() => close()} title="Das Cafe">
          Das <b>Cafe</b>
        </a>
      </li>
      <li>
        <a href="/#bar" offset={100} onClick={() => close()} title="Die Bar">
          Die <b>Bar</b>
        </a>
      </li>
      <li>
        <a
          href="/#eatanddrink"
          offset={100}
          onClick={() => close()}
          title="Eat & Drink"
        >
          <b>Eat</b> & <b>Drink</b>
        </a>
      </li>
      <li>
        <a
          href="/#kontakt"
          offset={100}
          onClick={() => close()}
          title="Lage & Kontakt"
        >
          Lage & <b>Kontakt</b>
        </a>
      </li>
    </ul>

    <a href="/">
      <img
        src={logo}
        alt="Logo vom Schäffners"
        title="Logo vom Schäffners"
        className="logo"
      />
    </a>

    <span className="iconwrapper">
      <a
        href="https://goo.gl/maps/UpQCymuWCq92"
        target="_blank"
        rel="noopener noreferrer"
        title="In Google Maps öffnen"
      >
        Schäffners Cafe & Bar <br />
        Bürgergasse 13 <br />
        8010 Graz
      </a>
    </span>

    <span className="iconwrapper">
      <a href="tel:06769706555">0676 9706555</a>
    </span>
    <span className="iconwrapper">
      <a href="mailto:info@schaeffners.at">info@schaeffners.at</a>
    </span>

    <span className="iconwrapper">
      <p>
        <b>Mo-Sa</b> 08:00-24:00
        <br />
        <b>So</b> 09:00-20:00
      </p>
    </span>
  </div>
);

export default Menu;