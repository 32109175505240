import React from "react";

const Image = props => (
  <img
    {...props}
    alt={props.alt}
    title={props.title ? props.title : props.alt}
  />
);

export default Image;
