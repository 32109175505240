import React, { Component } from "react";
import { Player } from "video-react";
import { Controller, Scene } from "react-scrollmagic/dist/index";
import formData from "form-data-to-object";
import "moment/locale/de-at";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import Slider from "react-slick";
import axios from "axios";

import logoWeiss from "../logo_weiss.svg";
import ic_ig from "../icons/ic_ig.svg";
import cover from "../images/cover.jpg";

import hero1Image1 from "../images/Fotokuchl-0308.jpg";
import hero1Image2 from "../images/Fotokuchl-0653.jpg";

import hero2Image1 from "../images/Fotokuchl-0036.jpg";
import hero2Image2 from "../images/Fotokuchl-1106.jpg";
import hero2Image3 from "../images/Fotokuchl-1075.jpg";

import hero3Image1 from "../images/Fotokuchl-0056.jpg";
import hero3Image2 from "../images/Fotokuchl-0168(1).jpg";
import hero3Image3 from "../images/Fotokuchl-0168.jpg";

import hero4Image1 from "../images/Fotokuchl-0473.jpg";
import hero4Image2 from "../images/Fotokuchl-0789.jpg";
import hero4Image3 from "../images/Fotokuchl-0831.jpg";

import foodImageMobile from "../images/mobileCardSwitch.jpg";
import Image from "../components/Image";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: grey
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Open Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(",")
  }
});

class Home extends Component {
  constructor(props) {
    super(props);

    this.video = React.createRef();

    this.state = {
      prevScrollpos: window.pageYOffset,
      visible: true,
      emailSent: false,
      editingMode: false,

      instagramImages: null
    };
  }

  componentDidMount() {
    this.fetchInstaImages();

    if (this.props.location.hash.length) {
      // const element = document.que
      document
        .getElementById(this.props.location.hash.replace("#", ""))
        .scrollIntoView();
    }
  }

  fetchInstaImages = () => {
    axios
      .get("/social/instagram_fetch.php")
      // axios.get('https://www.schaeffners.at/social/instagram_fetch.php')
      .then(response => {
        // handle success
        this.setState({ instagramImages: response.data.data });
      })
      .catch(function(error) {
        // handle error
        console.log(error);
      })
      .then(function() {
        // always executed
      });
  };

  submit = (values, pristineValues) => {
    // get all values and pristineValues on form submission
    const data = formData.toObj(values);
    console.log(data);

    axios
      .post("/Mailer/mail.php", data)
      // .post("http://schaeffners.at-react.test/public/Mailer/mail.php", data)
      .then(response => {
        this.setState({ emailSent: true });

        if (typeof window.fbq !== "undefined") {
          window.fbq("track", "Contact");
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  renderInstagram = () => {
    if (
      typeof this.state.instagramImages !== "undefined" &&
      this.state.instagramImages !== null &&
      this.state.instagramImages.length
    ) {
      const images = [];
      const counter = 12;

      this.state.instagramImages.forEach(picture => {
        const caption = picture.caption === null ? "" : picture.caption.text;

        if (images.length < counter) {
          images.push(
            <a
              className="instagramImage"
              href={picture.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="hover">
                <p>
                  {picture.likes.count} ♥ · {caption}
                </p>
              </div>
              <img src={picture.images.standard_resolution.url} alt={caption} />
            </a>
          );
        }
      });

      return <div className="instagramImages">{images}</div>;
    }
    return null;
  };

  render() {
    const slickSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true
    };

    return (
      <MuiThemeProvider theme={theme}>
        <div className="App">
          <Controller>
            <Scene>
              <div className="header">
                <Header match={this.props.match} />
              </div>
            </Scene>

            <section className="body">
              <div className="text">
                <Image
                  src={logoWeiss}
                  alt="Logo vom Schäffners"
                  className="logo"
                />
              </div>
              <svg
                version="1.1"
                className="mouse"
                width="80px"
                height="80px"
                viewBox="30.5 -9.5 554 813"
                enableBackground="new 30.5 -9.5 554 813"
              >
                <path
                  id="mousebody"
                  d="M328,0h-44C271.68,0,42,5.28,42,242v308c0,9.9,3.96,242,242,242h44c13.86,0,242-6.16,242-242V242C570,232.1,566.04,0,328,0z
               M526,550c0,191.62-177.76,198-198,198h-44C92.38,748,86,570.24,86,550V242C86,50.82,263.54,44,284,44h44
               c191.62,0,198,177.76,198,198V550z"
                />
                <circle cx="306" cy="231" r="45" />
              </svg>
              <Player
                poster={cover}
                autoPlay
                muted
                loop
                playsInline
                className="video-hero"
                ref={this.video}
                alt="Impressionen von Schäffners | Café · Bar am Tummelplatz"
              >
                <source src="/hero3.mp4" />
              </Player>
            </section>

            <Scene duration={600} classToggle="in-view">
              <section className="content clearfix firstAfterVideo">
                <div className="wrapper clearfix">
                  <div className="col md-col-7 mt3 px1 is-1-mobile">
                    <div className="headerbox mb2">
                      <h1>Getümmel am Tummelplatz</h1>
                    </div>
                    <div className="textbox">
                      {/*<h2 className="center">Schäffners</h2>*/}
                      <p>
                        Wo die Sonne sich ganztags Blicken lässt, wo sich alt
                        und jung zum Frühstück trifft, wo Feinschmecker auf
                        ihren Genuss kommen, wo abends gerne mit regionalen
                        Weinen & Gin Tonic angestoßen wird. Direkt am
                        Tummelplatz, im Herzen der Grazer Altstadt liegen Genuss
                        und Entspannung im Fokus.
                      </p>
                    </div>
                  </div>
                  <div className="col md-col-5 px1 is-2-mobile">
                    <Image
                      src={hero1Image1}
                      alt="Schäffners Gastgarten am Tummelplatz"
                      className="mtn2 mln1 slide-up-1 z3 objectFit is-hidden-mobile"
                    />
                    <Image
                      src={hero2Image2}
                      alt="Bernhard Schäffner Inhaber Schäffners"
                      className="tr3 mtn2 z2 objectFit is-hidden-desktop"
                    />
                    <Image
                      src={hero1Image2}
                      alt="Freunde beim Cocktailtrinken im Schäffners"
                      className="ml2 mtn5 slide-up-2 z2 objectFit is-hidden-mobile"
                    />
                    {/*<div className="ml2 mtn5 slide-up-2 z2 objectFit">*/}
                    {/*  <Player ref="player" poster={cover} autoPlay muted loop>*/}
                    {/*    <source src="/hero3.mp4" />*/}
                    {/*  </Player>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </section>
            </Scene>

            <Scene duration={600} classToggle="in-view">
              <section className="content clearfix">
                <div className="wrapper clearfix">
                  <div className="col md-col-5 px1 is-2-mobile">
                    <Image
                      src={hero2Image1}
                      alt="Barbereich im Schäffners"
                      className="objectFit z3 is-hidden-mobile"
                    />
                    <Image
                      src={cover}
                      alt="Flirten im Gastgarten am Tummelplatz"
                      className="objectFit z3 is-hidden-desktop"
                      style={{ height: 500 }}
                    />

                    <Image
                      src={hero2Image2}
                      alt="Schinken und Wurstspezialitäten mit Bier"
                      className="tr3 mtn2 z2 objectFit is-hidden-mobile"
                    />
                    <Image
                      src={hero2Image3}
                      alt="Pikantes Croissant mit Orangensaft zum Frühstück im Schäffners"
                      className="z3 mtn15 objectFit is-hidden-mobile"
                    />
                  </div>
                  <div className="col md-col-7 mt3 mb4 px1 is-1-mobile">
                    <div className="headerbox mb4">
                      <h2>
                        Urbanes Design und leckere Drinks laden zum Verweilen
                        ein.{" "}
                      </h2>
                    </div>

                    <div className="textbox">
                      <p>
                        Ein gemütlicher Kaffee am Morgen, ein Lillet Berry und
                        Flammkuchen zur Mittagszeit, After-Business-Drinks oder
                        ein ausgiebiger Brunch am Wochenende. Schäffners am
                        Tummelplatz punktet mit einem facettenreichen Angebot,
                        legerer Atmosphäre und einem großzügigen Gastgarten, der
                        ohnedies zu den beliebtesten der Stadt zählt. Ein
                        ungezwungenes, gemütliches Gastrokonzept für
                        Stadtschwärmer, die gerne Verweilen und Genießen.
                      </p>
                      <p>
                        Geh auf Nummer sicher und reserviere dir jetzt deinen
                        Lieblingsplatz oder sei einfach spontan und schau vorbei
                        wann immer du Lust dazu hast. Während den Öffnungszeiten
                        natürlich. Diese sind übrigens Mo-Sa 08-24:00 Uhr und So
                        von 09-20:00 Uhr.
                      </p>
                      <a href="#kontakt" className="button">
                        Jetzt Reservieren
                      </a>
                    </div>
                  </div>
                </div>
              </section>
            </Scene>

            <Scene duration={600} classToggle="in-view">
              <section className="content clearfix" id="cafe">
                <div className="wrapper clearfix mt4">
                  <div className="col md-col-7 mt3 px1 is-1-mobile">
                    <div className="headerbox mb4">
                      <h2>
                        Auszeit gefällig?
                        <br />
                        Relaxe bei
                        <br />
                        uns im Café
                      </h2>
                    </div>
                    <div className="textbox">
                      <p>
                        Ob mit der Tageszeitung, deinem Notebook, beim legeren
                        Treffen mit Freunden oder auch beim wichtigen Business
                        Meeting bietet dir das Schäffners drinnen und draußen
                        eine Wohlfühlatmosphäre zum chillen, tratschen,
                        besprechen, verweilen und natürlich zum Essen und
                        Trinken.
                      </p>
                    </div>
                  </div>
                  <div className="col md-col-5 px1 is-2-mobile">
                    <Image
                      src={hero3Image1}
                      alt="Kaffee und Topfenstrudel im Schäffners"
                      className="objectFit mtn4 mr2 z3 is-hidden-mobile"
                    />
                    <Image
                      src={hero3Image2}
                      alt="Frühstück mit Freunden im Schäffners"
                      className="tl4 mtn20 z4 objectFit is-hidden-mobile"
                    />
                    <Image
                      src={hero3Image3}
                      alt="Frühstücksangebot, Brunchen im Schäffners"
                      className="z3 mtn5 tl1 objectFit is-hidden-mobile"
                    />
                  </div>
                </div>
              </section>
            </Scene>

            <div id="eatanddrink" />

            <Scene duration={600} classToggle="in-view">
              <section className="content clearfix eatanddrink is-hidden-mobile">
                <div className="divider">
                  <div className="half left eat">
                    <Link to="/eat" className="text">
                      <h3>Eat</h3>
                      <p>Frühstück, Mittag und für den Hunger zwischendurch.</p>
                      <span className="button">Zu den Speisen</span>
                    </Link>
                  </div>
                  <div className="half right drinks">
                    <Link to="/drinks" className="text">
                      <h3>Drinks</h3>
                      <p>Alles was den Durst löscht und noch viel mehr.</p>
                      <span className="button">Zu den Drinks</span>
                    </Link>
                  </div>
                </div>
              </section>
            </Scene>

            <Scene duration={600} classToggle="in-view">
              <section className="content clearfix eatanddrink is-hidden-desktop">
                <div className="wrapper clearfix">
                  <div className="col is-1-mobile center">
                    <Link
                      to="/eat"
                      className="button"
                      style={{
                        width: "80%",
                        margin: 0,
                        transform: "translateY(50%)"
                      }}
                    >
                      Zu den Speisen
                    </Link>
                    <Image
                      src={foodImageMobile}
                      alt="Frühstücksangebot, Brunchen im Schäffners"
                      className="objectFit z3"
                    />
                    <Link
                      to="/drinks"
                      className="button"
                      style={{
                        width: "80%",
                        margin: 0,
                        transform: "translateY(-50%)"
                      }}
                    >
                      Zu den Drinks
                    </Link>
                  </div>
                </div>
              </section>
            </Scene>

            <Image
              src={hero4Image1}
              alt=""
              className="z2 mtn5 tl1 objectFit is-hidden-desktop"
            />

            <Scene duration={600} classToggle="in-view">
              <section className="content clearfix" id="bar">
                <div className="wrapper clearfix">
                  <div className="col md-col-7 mt3 px1 is-1-mobile">
                    <div className="headerbox mb4">
                      <h2>
                        {" "}
                        Für alle Genießer, Partytiger, Cocktail Fans und
                        Feinschmecker
                      </h2>
                    </div>
                    <div className="textbox">
                      <p className="">
                        Das wohlverdiente Bier mit dem Lieblingskollegen, das
                        längst überfällige Treffen mit der besten Freundin, das
                        erste Date nach nächtelangen WhatsApp Konversationen
                        oder doch die wilde Geburtstagsfeier deines Kumpels. Was
                        auch immer dein Abend im Schäffners bringt, wir sind
                        gewappnet und freuen uns dir in jeder Lage & Laune einen
                        großartigen Abend zu bescheren.
                      </p>
                    </div>
                  </div>
                  <div className="col md-col-5 px1 is-2-mobile">
                    <Image
                      src={hero4Image1}
                      alt="Bernd Schäffner mixt Cocktails an der Bar"
                      className="objectFit mt4 z3 is-hidden-mobile"
                    />
                    <Image
                      src={hero4Image2}
                      alt="Freunde beim Cocktailtrinken im Schäffners"
                      className="tl3 mtn4 z4 objectFit is-hidden-mobile"
                    />
                    <Image
                      src={hero4Image3}
                      alt="Schäffners Logo an der Wand in der Bar"
                      className="z2 mtn5 tl1 objectFit is-hidden-mobile"
                    />
                  </div>
                </div>
              </section>
            </Scene>

            <div className="instagram mt4">
              <h2>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/schaeffners/"
                  title="Folge uns auf Instagram!"
                >
                  <Image
                    src={ic_ig}
                    alt="Instagram"
                    title="Folge uns auf Instagram!"
                  />
                  #schaeffners
                </a>
              </h2>
              {this.renderInstagram()}
            </div>

            <div className="testimonial-wrapper mt4">
              <Slider {...slickSettings} className="testimonials">
                <div>
                  <div className="testimonial">
                    <blockquote>
                      Super Lokal, mit dem besten Gastgarten überhaupt!
                    </blockquote>
                    <span className="author">Wilma Johanna</span>
                  </div>
                </div>
                <div>
                  <div className="testimonial">
                    <blockquote>
                      Perfekter Start in den Montag!
                      <br />
                      Sonniger, gemütlicher Gastgarten, freundliches Personal,
                      Kaffeegenuss pur!
                    </blockquote>
                    <span className="author">Alexandra Grabner</span>
                  </div>
                </div>
                <div>
                  <div className="testimonial">
                    <blockquote>
                      Sehr nett eingerichtete Bar bzw. Cafe. Da das Wetter
                      gepasst hat konnten wir draußen frühstücken. Nettes Platzl
                      um im freien etwas zu trinken/essen. Sehr freundliches und
                      bemühtes Personal
                    </blockquote>
                    <span className="author">Thomas J</span>
                  </div>
                </div>
              </Slider>
            </div>

            <section className="content clearfix comeAndFindUs">
              <div className="wrapper clearfix center">
                <div className="col md-col-2" />
                <div className="col-12 md-col-8 mt3  px2 is-1-mobile">
                  <div className="headerbox">
                    <h2>Come and Find Us</h2>
                  </div>
                </div>
              </div>
            </section>

            <Player
              poster={cover}
              autoPlay
              muted
              loop
              playsInline
              className="video-anreise"
              ref={this.video}
              alt="Wegbeschreibung zur Schäffners | Café · Bar am Tummelplatz"
            >
              <source src="/anreise.mp4" />
            </Player>

            <Footer />
          </Controller>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default Home;
