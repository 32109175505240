import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./pages/Home";
import Drinks from "./pages/Drinks";
import Eat from "./pages/Eat";
import "./App.scss";
import * as Sentry from "@sentry/browser";

Sentry.init({
 dsn: "https://1338dac3d4ad486ba18ab68670d2d183@sentry.io/1466692"
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    return (
      <Router>
        <div>
          <Route exact path="/" component={Home} />
          <Route exact path="/drinks" component={Drinks} />
          <Route exact path="/eat" component={Eat} />
        </div>
      </Router>
    );
  }
}

export default App;
