import React, { Component } from "react";
import { Controller, Scene } from "react-scrollmagic/dist/index";
import "moment/locale/de-at";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import axios from "axios";

// import hero1Image1 from "../images/Fotokuchl-0308.jpg";
// import hero1Image2 from "../images/Fotokuchl-0653.jpg";

// import hero2Image1 from "../images/Fotokuchl-0036.jpg";
// import hero2Image2 from "../images/Fotokuchl-1106.jpg";
// import hero2Image3 from "../images/Fotokuchl-1075.jpg";

import hero3Image1 from "../images/Fotokuchl-0056.jpg";
// import hero3Image2 from "../images/Fotokuchl-0168(1).jpg";
import hero3Image3 from "../images/Fotokuchl-0168.jpg";

// import hero4Image1 from "../images/Fotokuchl-0473.jpg";
// import hero4Image2 from "../images/Fotokuchl-0789.jpg";
// import hero4Image3 from "../images/Fotokuchl-0831.jpg";

import ImageEat from "../images/Fotokuchl-0248.jpg";
import ImageEat2 from "../images/Fotokuchl-0044.jpg";
import ImageEat3 from "../images/Fotokuchl-0358.jpg";
import ImageEat4 from "../images/Fotokuchl-0285.jpg";
// import ImageEat5 from "../images/Fotokuchl-0296.jpg";
import ImageEat6 from "../images/Fotokuchl-1408.jpg";
import ImageEat7 from "../images/Fotokuchl-0331.jpg";
import ImageEat8 from "../images/Fotokuchl-0355.jpg";
import ImageEat9 from "../images/Fotokuchl-0354.jpg";
import Image from "../components/Image";
import Header from "../components/Header";
import imageKarte from "../images/karte.jpg";
import Footer from "../components/Footer";

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: grey
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Open Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(",")
  }
});

class Eat extends Component {
  constructor(props) {
    super(props);

    this.video = React.createRef();

    this.state = {
      prevScrollpos: window.pageYOffset,
      visible: true,
      emailSent: false,
      editingMode: false,

      instagramImages: null
    };
  }

  componentDidMount() {
    this.fetchInstaImages();
    window.scrollTo(0, 0);
  }

  fetchInstaImages = () => {
    axios
      .get("/social/instagram_fetch.php")
      // axios.get('https://www.schaeffners.at/social/instagram_fetch.php')
      .then(response => {
        // handle success
        this.setState({ instagramImages: response.data.data });
      })
      .catch(function(error) {
        // handle error
        console.log(error);
      })
      .then(function() {
        // always executed
      });
  };

  renderInstagram = () => {
    if (
      typeof this.state.instagramImages !== "undefined" &&
      this.state.instagramImages !== null &&
      this.state.instagramImages.length
    ) {
      const images = [];
      const counter = 12;

      this.state.instagramImages.forEach(picture => {
        if (images.length < counter) {
          images.push(
            <a
              className="instagramImage"
              href={picture.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="hover">
                <p>
                  {picture.likes.count} ♥ · {picture.caption.text}
                </p>
              </div>
              <img
                src={picture.images.standard_resolution.url}
                alt={picture.caption.text}
              />
            </a>
          );
        }
      });

      return <div className="instagramImages">{images}</div>;
    }
    return null;
  };

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <div className="App">
          <Controller>
            <Scene>
              <div className="header">
                <Header match={this.props.match} />
              </div>
            </Scene>

            <div className="mt4" />

            <Scene duration={600} classToggle="in-view">
              <section className="content clearfix firstAfterVideo">
                <div className="wrapper clearfix">
                  <div className="col md-col-7 mt3 px1 is-1-mobile">
                    <div className="headerbox mb2">
                      <h1>
                        Frühstück, Mittag und für den Hunger zwischendurch
                      </h1>
                    </div>
                    <div className="textbox">
                      <p>
                        Ob mit der Tageszeitung, deinem Notebook, beim legeren
                        Treffen mit Freunden oder auch beim wichtigen Business
                        Meeting bietet dir das Schäffners in Graz eine
                        Wohlfühlatmosphäre zum chillen, tratschen, besprechen,
                        verweilen und natürlich zum Essen und Trinken.
                      </p>
                    </div>
                  </div>
                  <div className="col md-col-5 px1 is-2-mobile">
                    <Image
                      src={ImageEat}
                      alt="Schäffners Gastgarten am Tummelplatz"
                      className="mtn2 mln1 slide-up-1 z3 objectFit is-hidden-mobile"
                    />
                    <Image
                      src={ImageEat}
                      alt="Bernhard Schäffner Inhaber Schäffners"
                      className="tr3 mtn2 z2 objectFit is-hidden-desktop"
                    />
                    {/*<Image*/}
                    {/*  src={ImageEat2}*/}
                    {/*  alt="Freunde beim Cocktailtrinken im Schäffners"*/}
                    {/*  className="ml2 mtn5 slide-up-2 z2 objectFit is-hidden-mobile"*/}
                    {/*/>*/}
                  </div>
                </div>
              </section>
            </Scene>

            <Scene duration={600} classToggle="in-view">
              <section className="content clearfix" id="cafe">
                <div className="wrapper clearfix mt4">
                  <div className="col md-col-7 mt3 px1 is-1-mobile">
                    <div className="headerbox mb2">
                      <h2>Frühstück und Brunch</h2>
                    </div>
                    <div className="textbox">
                      <p>
                        <b>Gutääähn Morgen, Kaffee gefällig?</b>
                      </p>
                      <p>
                        Wie in Österreich üblich zelebrieren wir im Schäffners
                        den Wiener Kaffeehaustrend. Unser Kaffee wird aus vollen
                        Bohnen frisch gemahlen zubereitet. Für den perfekten
                        Kaffee ist nicht nur der ideale Mahlgrad entscheidend,
                        sondern auch die Röstintensität. Daher achten wir beim
                        Kauf auf ein vollmundiges Röstaroma der Kaffeesorte.
                      </p>
                      {/*<p>*/}
                      {/*  Der frühe Vogel kann uns mal gern haben denn im*/}
                      {/*  Schäffners gibt’s Frühstück von Montag-Freitag 08:00 -*/}
                      {/*  12:00 <br />*/}
                      {/*  und Samstag + Sonntag von 09:00-14:00.*/}
                      {/*</p>*/}
                      <p>
                        <b>Hungry?</b>
                      </p>
                      <p>
                        Ei-liebhaber verpflegen wir mit der klassischen
                        Eierspeis, Ham & Eggs oder einem schmackhaften
                        weichgekochtem Ei.
                      </p>
                      <p>
                        Weiters bieten wir auch drei fabelhafte Toastvariationen
                        die sich auf Wunsch auch gerne mit Spiegelei, Ananas und
                        vielem weiteren belegen wonach du gerade Lust und Laune
                        hast. Ja du hast richtig gelesen.. der Toast Hawaii ist
                        wieder da!
                      </p>
                      <p>
                        Starte deinen Tag mit ofenfrischen Croissants gefüllt
                        ganz nach deinem Geschmack: Ob klassisch mit Schinken &
                        Käse, Vegetarisch mit Guacamole und Zupfsalat, mit Lachs
                        und Krenn oder als süße Version mit Honig oder Nutella.
                      </p>

                      <p>
                        Dazu noch einen vitaminreichen Smoothie oder ein Glas
                        frisch gepressten Orangen- oder Karottensaft und einem
                        erfolgreichen Tag steht nichts mehr im Weg.
                      </p>
                      <p>
                        Das ganze Frühstücksangebot findest du in unserer Karte.
                      </p>
                      <p>
                        ACHTUNG: Bei uns kann ganz schön viel los sein.
                        Hinterlass uns bitte eine Nachricht und reservier dir
                        deinen Frühstücksplatz.
                        <a href="#kontakt" className="button">
                          Zum Kontaktformular
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="col md-col-5 px1 is-2-mobile">
                    <Image
                      src={hero3Image1}
                      alt="Kaffee und Topfenstrudel im Schäffners"
                      className="objectFit mtn2 z3 is-hidden-mobile"
                      style={{ height: 400 }}
                    />
                    {/*<Image*/}
                    {/*  src={hero3Image2}*/}
                    {/*  alt="Frühstück mit Freunden im Schäffners"*/}
                    {/*  className="tl4 mtn20 z4 objectFit is-hidden-mobile"*/}
                    {/*/>*/}
                    <Image
                      src={hero3Image3}
                      alt="Frühstücksangebot, Brunchen im Schäffners"
                      className="z3 mtn2 objectFit tr1 is-hidden-mobile"
                      // style={{height: 360}}
                    />
                    <div className="headerbox mb2 mtn2">
                      <h4>
                        Langschläfer? <br />
                        Frühstück gibt's von <br />
                        <br />
                        Mo - Fr 08:00-12:00 <br />
                        Sa & So 09:00-14:00.
                      </h4>
                    </div>
                    <Image
                      src={ImageEat2}
                      alt="Frühstücksangebot, Brunchen im Schäffners"
                      className="z3 mtn4 objectFit tl1 is-hidden-mobile"
                      // style={{height: 360}}
                    />
                  </div>
                </div>
              </section>
            </Scene>

            <Image
              src={ImageEat3}
              alt=""
              className="z2 mtn5 tl1 objectFit is-hidden-desktop"
            />

            <Scene duration={600} classToggle="in-view">
              <section className="content clearfix">
                <div className="wrapper clearfix">
                  <div className="col md-col-5 px1 is-2-mobile">
                    <Image
                      src={ImageEat3}
                      alt="Barbereich im Schäffners"
                      className="objectFit z3 is-hidden-mobile"
                    />

                    <Image
                      src={ImageEat4}
                      alt="Schinken und Wurstspezialitäten mit Bier"
                      className="tr1 mtn2 z2 objectFit is-hidden-mobile"
                      style={{ height: 400 }}
                    />
                    {/*<Image*/}
                    {/*  src={ImageEat5}*/}
                    {/*  alt="Pikantes Croissant mit Orangensaft zum Frühstück im Schäffners"*/}
                    {/*  className="z3 mtn5 objectFit is-hidden-mobile"*/}
                    {/*/>*/}
                  </div>
                  <div className="col md-col-7 mt3 mb4 px1 is-1-mobile">
                    <div className="headerbox mb2">
                      <h2>Mittags ist Flammkuchenzeit!</h2>
                    </div>

                    <div className="textbox">
                      <p>
                        Schäffners Flammkuchen überzeugen mit luftig lockerem
                        Teig, knusprigem Boden und super leckeren Belägen ganz
                        nach deinem Geschmack. Neben der regionalen Salami und
                        Speckvariante gibt’s den Flammkuchen auch mit Lachs und
                        als Veggi-Version. Flammkuchen gibt’s zu jeder Tages &
                        Nachtzeit. Optimal für die leichte Mittagspause, ein
                        herzhaftes Abendmahl oder einfach als Snack für
                        zwischendurch.
                      </p>
                      {/*<a href="#kontakt" className="button">*/}
                      {/*  Jetzt Reservieren*/}
                      {/*</a>*/}
                    </div>
                  </div>
                </div>
              </section>
            </Scene>

            <Image
              src={ImageEat9}
              alt=""
              className="z2 mtn5 tl1 objectFit is-hidden-desktop"
            />

            <Scene duration={600} classToggle="in-view">
              <section className="content clearfix" id="bar">
                <div className="wrapper clearfix">
                  <div className="col md-col-7 mt3 px1 is-1-mobile">
                    <div className="headerbox mb2">
                      <h2>Weinbegleiter & regionale Feinschmeckerteller</h2>
                    </div>
                    <div className="textbox">
                      <p>
                        Ausgezeichnet ist sie, die kalte, steirische Jause. Und
                        wer sie genießen will muss nun nicht mehr in die
                        Südsteiermark. Wer Hunger auf Regionalität hat oder den
                        optimalen Weinbegleiter sucht greift am besten auf
                        unsere Schinken und Wurstspezialitätenteller von{" "}
                        <a href="http://www.steirerei.st/">JAGA Steirerei</a>{" "}
                        oder dem ausgewählten Käseteller aus einem Mix aus
                        Weich- und Hartkäse zurück.
                      </p>
                    </div>
                  </div>
                  <div className="col md-col-5 px1 is-2-mobile">
                    <Image
                      src={ImageEat7}
                      alt="Bernd Schäffner mixt Cocktails an der Bar"
                      className="objectFit mt2 z3 is-hidden-mobile"
                      style={{ height: 500 }}
                    />
                    <Image
                      src={ImageEat8}
                      alt="Freunde beim Cocktailtrinken im Schäffners"
                      className="tl1 mtn2 z4 objectFit is-hidden-mobile"
                    />
                  </div>
                </div>
              </section>
            </Scene>

            <Image
              src={ImageEat6}
              alt="Schinken und Wurstspezialitäten mit Bier"
              className="tr1 z2 objectFit is-hidden-desktop"
              style={{ height: 250 }}
            />

            <Scene duration={600} classToggle="in-view">
              <section className="content clearfix">
                <div className="wrapper clearfix">
                  <div className="col md-col-5 px1 is-2-mobile">
                    {/*<Image*/}
                    {/*  src={ImageEat2}*/}
                    {/*  alt="Barbereich im Schäffners"*/}
                    {/*  className="objectFit z3 is-hidden-mobile"*/}
                    {/*/>*/}
                    {/*<Image*/}
                    {/*  src={ImageEat2}*/}
                    {/*  alt="Flirten im Gastgarten am Tummelplatz"*/}
                    {/*  className="objectFit z3 is-hidden-desktop"*/}
                    {/*  style={{ height: 450 }}*/}
                    {/*/>*/}

                    <Image
                      src={ImageEat6}
                      alt="Schinken und Wurstspezialitäten mit Bier"
                      className="tr1 z2 objectFit is-hidden-mobile"
                      style={{ height: 450 }}
                    />

                    {/*<Image*/}
                    {/*  src={hero2Image3}*/}
                    {/*  alt="Pikantes Croissant mit Orangensaft zum Frühstück im Schäffners"*/}
                    {/*  className="z3 mtn15 objectFit is-hidden-mobile"*/}
                    {/*/>*/}
                  </div>
                  <div className="col md-col-7 mt3 mb4 px1 is-1-mobile">
                    <div className="headerbox mb2">
                      <h2>Oma's Rhabarberkuchen & mehr</h2>
                    </div>

                    <div className="textbox">
                      <p>
                        Unsere Vitrine ist ständig mit feinen Kuchen und
                        Tortenvariationen gefüllt. Um für Abwechslung zu sorgen
                        variiert die Auswahl ständig. Unser Personal kennt die
                        tägliche Empfehlung. <br />
                        Tipp: Frag nach Oma Schäffners Rhabarberkuchen
                      </p>
                      {/*<a href="#kontakt" className="button">*/}
                      {/*  Jetzt Reservieren*/}
                      {/*</a>*/}
                    </div>
                  </div>
                </div>
              </section>
            </Scene>

            <div id="eatanddrink" />

            <Scene duration={600} classToggle="in-view">
              <section className="content clearfix eatanddrink is-hidden-desktop">
                <div className="wrapper clearfix">
                  <div className="col is-1-mobile">
                    <Image
                      src={imageKarte}
                      alt="Frühstücksangebot, Brunchen im Schäffners"
                      className="objectFit mt4 z3"
                    />
                  </div>
                  <div className="col col-12 md-col-7 mb3 px1 is-2-mobile">
                    <div className="headerbox mb2 mt3">
                      <h2>Die Karte</h2>
                    </div>
                    <div className="textbox">
                      <p>Gibts hier in voller Pracht anzusehen</p>
                      <a href="Karte.pdf" target="_blank" className="button">
                        Zur Karte
                      </a>
                    </div>
                  </div>
                </div>
              </section>
            </Scene>

            <Scene duration={600} classToggle="in-view">
              <section className="content clearfix eatanddrink is-hidden-mobile">
                <div className="wrapper clearfix">
                  <div className="col md-col-5" />
                  <div className="col md-col-7 mt4 mb3 px1 is-1-mobile">
                    <div className="headerbox mb2 mt3 center">
                      <h2>Die Karte</h2>
                      <p>Gibts hier in voller Pracht anzusehen</p>
                      <a href="Karte.pdf" target="_blank" className="button">
                        Zur Karte
                      </a>
                    </div>
                  </div>
                </div>
              </section>
            </Scene>

            <Footer />
          </Controller>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default Eat;
