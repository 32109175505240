import { messageMap } from "react-material-ui-form/dist/validation";
import React, { Component } from "react";
import formData from "form-data-to-object";
import axios from "axios";
import MaterialUIForm from "react-material-ui-form";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Image from "./Image";
import logoWeiss from "../logo_weiss.svg";
import ic_location from "../icons/ic_location.svg";
import ic_phone from "../icons/ic_phone.svg";
import ic_mail from "../icons/ic_mail.svg";
import ic_clock from "../icons/ic_clock.svg";
import rateUs from "../icons/RateUs.svg";

const customFormMsg = Object.assign(messageMap, {
  isRequired: "Wird benötigt.",
  isEmail: "Dies ist keine gültige E-Mail Adresse",
  isLength: "Must be 2-50 characters"
});

export class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
      emailSent: false,
      editingMode: false
    };
  }

  submit = (values, pristineValues) => {
    // get all values and pristineValues on form submission
    const data = formData.toObj(values);

    axios
      .post("/Mailer/mail.php", data)
      // .post("http://schaeffners.at-react.test/public/Mailer/mail.php", data)
      .then(response => {
        this.setState({ emailSent: true });

        if (typeof window.fbq !== "undefined") {
          window.fbq("track", "Contact");
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  renderContactForm = () => {
    if (this.state.emailSent) {
      return (
        <div className="contactForm">
          <h3>Vielen Dank für deine Nachricht</h3>
          <p>Wir werden uns bald bei dir melden.</p>
        </div>
      );
    }
    return (
      <div className="contactForm">
        <p>
          Du möchtest einen Tisch reservieren, uns Feedback geben oder einfach
          nur Hallo sagen? Wir freuen uns auf deine Nachricht.
        </p>
        <MaterialUIForm
          onSubmit={(values, pristineValues) =>
            this.submit(values, pristineValues)
          }
          autoComplete={"on"}
          validation={{ messageMap: customFormMsg }}
        >
          <TextField
            label="Name"
            type="text"
            name="name"
            value=""
            data-validators="isRequired,isAlpha"
            onChange={this.customInputHandler}
            className={"material-textField"}
          />
          <TextField
            label="E-Mail"
            type="text"
            name="email"
            value=""
            data-validators="isRequired,isEmail"
            onChange={this.customInputHandler}
            className={"material-textField"}
          />
          <TextField
            label="Nachricht"
            type="text"
            name="message"
            value=""
            data-validators="isRequired"
            onChange={this.customInputHandler}
            className={"material-textField"}
            multiline
            rowsMax={5}
            rows={5}
            placeholder="Möchtest du reservieren? Hast du Anregungen? Hast du ein Kompliment für den Schäff übrig?"
          />
          <Button variant="contained" color="primary" type="submit">
            Senden
          </Button>
        </MaterialUIForm>
      </div>
    );
  };

  render() {
    return (
      <footer className="" id="kontakt">
        <div className="wrapper clearfix">
          {/*<div className="col-12 md-col-8 px">{this.renderContactForm()}</div>*/}
          <div className="col-12 md-col-12 px2 center">
            <Image
              src={logoWeiss}
              alt="Logo vom Schäffners"
              className="logo"
              width={200}
            />
            <p className="center flex flex-column items-center">
              <span className="iconwrapper">
                <Image src={ic_location} alt="" />
                <a
                  href="https://goo.gl/maps/UpQCymuWCq92"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Schäffners Cafe & Bar <br />
                  Bürgergasse 13 <br />
                  8010 Graz
                </a>
              </span>
              <span className="iconwrapper">
                <Image src={ic_phone} alt="" />
                <a href="tel:06769706555">0676 9706555</a>
              </span>
              <span className="iconwrapper">
                <Image src={ic_mail} alt="" />
                <a href="mailto:info@schaeffners.at">info@schaeffners.at</a>
              </span>
              <span className="iconwrapper">
                <Image src={ic_clock} alt="" />
                <span>
                  <b>Mo-Sa</b> 08:00-24:00
                  <br />
                  <b>So</b> 09:00-20:00
                </span>
              </span>
              <br />
              <br />
              <a
                href="https://search.google.com/local/writereview?placeid=ChIJF7COzQg1bkcRUL3GT69pm0Q"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src={rateUs} alt="" />
              </a>
            </p>
          </div>

          <div className="col-12 bottomBar center">
            © {new Date().getFullYear()}, Schäffners | Café · Bar am Tummelplatz
            <br />
            <a href="datenschutz.html" target="_blank">
              Datenschutz & Impressum
            </a>
            <br />
            Webdesign by{" "}
            <a
              href="https://www.saraibrahim.at"
              target="_blank"
              rel="noopener noreferrer"
            >
              SaraIbrahim.at
            </a>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
