import React, { Component } from "react";
import { Controller, Scene } from "react-scrollmagic/dist/index";
import "moment/locale/de-at";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import axios from "axios";

// import hero1Image1 from "../images/Fotokuchl-0308.jpg";
import hero1Image2 from "../images/Fotokuchl-0653.jpg";

// import hero2Image1 from "../images/Fotokuchl-0036.jpg";
import hero2Image2 from "../images/Fotokuchl-1106.jpg";
// import hero2Image3 from "../images/Fotokuchl-1075.jpg";

// import hero3Image1 from "../images/Fotokuchl-0056.jpg";
// import hero3Image2 from "../images/Fotokuchl-0168(1).jpg";
// import hero3Image3 from "../images/Fotokuchl-0168.jpg";

import imageCocktails from "../images/Fotokuchl-0427.jpg";
import imageWine from "../images/Fotokuchl-1354.jpg";
import imageWine2 from "../images/zachariah-hagy-484676-unsplash.jpg";
import imageGin from "../images/Fotokuchl-1328.jpg";
import imageGin2 from "../images/Fotokuchl-1326.jpg";
import imageGin3 from "../images/Fotokuchl-1434.jpg";
import imageKarte from "../images/karte.jpg";
// import imageKarteDesktop from "../images/karteDesktop.jpg";

// import imageVeuve from "../images/Fotokuchl-1342.jpg";
import imageRum from "../images/Platinum-Reserve.png";
import imageWineFood from "../images/Fotokuchl-0338.jpg";

import Image from "../components/Image";
import Header from "../components/Header";
import Footer from "../components/Footer";
// import foodImage from "../images/Fotokuchl-1143.jpg";

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: grey
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Open Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(",")
  }
});

class Drinks extends Component {
  constructor(props) {
    super(props);

    this.video = React.createRef();

    this.state = {
      prevScrollpos: window.pageYOffset,
      visible: true,
      emailSent: false,
      editingMode: false,

      instagramImages: null
    };
  }

  componentDidMount() {
    this.fetchInstaImages();
    window.scrollTo(0, 0);
  }

  fetchInstaImages = () => {
    axios
      .get("/social/instagram_fetch.php")
      // axios.get('https://www.schaeffners.at/social/instagram_fetch.php')
      .then(response => {
        // handle success
        this.setState({ instagramImages: response.data.data });
      })
      .catch(function(error) {
        // handle error
        console.log(error);
      })
      .then(function() {
        // always executed
      });
  };

  renderInstagram = () => {
    if (
      typeof this.state.instagramImages !== "undefined" &&
      this.state.instagramImages !== null &&
      this.state.instagramImages.length
    ) {
      const images = [];
      const counter = 12;

      this.state.instagramImages.forEach(picture => {
        if (images.length < counter) {
          images.push(
            <a
              className="instagramImage"
              href={picture.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="hover">
                <p>
                  {picture.likes.count} ♥ · {picture.caption.text}
                </p>
              </div>
              <img
                src={picture.images.standard_resolution.url}
                alt={picture.caption.text}
              />
            </a>
          );
        }
      });

      return <div className="instagramImages">{images}</div>;
    }
    return null;
  };

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <div className="App">
          <Controller>
            <Scene>
              <div className="header">
                <Header match={this.props.match} />
              </div>
            </Scene>

            <Image
              src={imageCocktails}
              alt="Flirten im Gastgarten am Tummelplatz"
              className="objectFit z3 is-hidden-desktop"
            />

            <div className="mt4" />

            <Scene duration={600} classToggle="in-view">
              <section className="content clearfix firstAfterVideo">
                <div className="wrapper clearfix">
                  <div className="col md-col-7 mt3 px1 is-1-mobile">
                    <div className="headerbox mb2">
                      <h1>Mein Drink. meine Bar. Mein Graz.</h1>
                    </div>
                    <div className="textbox">
                      <h2>Alles was den Durst löscht und noch viel mehr. </h2>
                      <p>
                        Neben jeglichen Klassikern an alkoholischen Drinks,
                        einer großen Auswahl an regionalen Weinen sowie
                        heimischen und internationalen Gin Spezialitäten bietet
                        das Schäffners auch, ausgezeichnete Cocktails, seltene
                        Spirituosen und exklusiven Champagner.
                      </p>
                      <p>
                        Einem hervorragenden Abend im Zentrum der Grazer
                        Altstadt begleitet von dem ein oder anderen Drink,
                        regionalen Snacks, lässigen Sounds und moderner, urbaner
                        Atmosphäre steht also nichts mehr im Weg.
                      </p>
                      <p>
                        Unsere Empfehlungen möchten wir euch gleich mal
                        vorstellen.
                      </p>
                    </div>
                  </div>
                  <div className="col md-col-5 px1 is-2-mobile">
                    <Image
                      src={imageWineFood}
                      alt="Schäffners Gastgarten am Tummelplatz"
                      className="mtn2 mln1 slide-up-1 z3 objectFit is-hidden-mobile"
                    />
                    <Image
                      src={imageWine}
                      alt="Bernhard Schäffner Inhaber Schäffners"
                      className="tr3 mtn2 z2 objectFit is-hidden-desktop"
                      style={{
                        height: 400,
                        width: "100%",
                        objectFit: "cover",
                        objectFitPosition: "center top"
                      }}
                    />
                    <Image
                      src={hero1Image2}
                      alt="Freunde beim Cocktailtrinken im Schäffners"
                      className="ml2 mtn5 slide-up-2 z2 objectFit is-hidden-mobile"
                    />
                  </div>
                </div>
              </section>
            </Scene>

            <Scene duration={600} classToggle="in-view">
              <section className="content clearfix">
                <div className="wrapper clearfix">
                  <div className="col md-col-5 px1 is-2-mobile">
                    <Image
                      src={imageWine}
                      alt="Barbereich im Schäffners"
                      className="objectFit z1 mt2 is-hidden-mobile"
                    />
                    <Image
                      src={imageGin}
                      alt="Flirten im Gastgarten am Tummelplatz"
                      className="objectFit z3 is-hidden-desktop"
                    />

                    <Image
                      src={imageWine2}
                      alt="Schinken und Wurstspezialitäten mit Bier"
                      className="tr1 mtn2 z2 objectFit is-hidden-mobile"
                    />
                    {/*<Image*/}
                    {/*  src={hero2Image3}*/}
                    {/*  alt="Pikantes Croissant mit Orangensaft zum Frühstück im Schäffners"*/}
                    {/*  className="z3 mtn15 objectFit is-hidden-mobile"*/}
                    {/*/>*/}
                  </div>
                  <div className="col md-col-7 mt3 mb4 px1 is-1-mobile">
                    <div className="headerbox mb2">
                      <h2>Wer Wein genießt kostet Geheimnisse.</h2>
                    </div>

                    <div className="textbox">
                      <p>
                        Wähle zwischen ausgewählten Spitzen- Weißweinen wie dem
                        Welschriesling, dem Weissburgunder, dem Sauvignon Blanc,
                        dem beliebten Muskateller, dem Grauburgunder oder dem
                        Säumling. Oder probiere sie einfach alle. Ob als weißen
                        Spritzer oder pur überlassen wir dir.
                      </p>
                      <p>
                        Rotwein Genießer wählen zwischen Zweigelt, Cabernet
                        Sauvignon und Cuvee.
                      </p>
                      <h2>
                        {" "}
                        Es ist Zeit auf den Tischen zu tanzen und Champagner zu
                        trinken.{" "}
                      </h2>
                      <p>
                        Zur Feier des Tages oder zur Feier jeden Tages empfiehlt
                        sich Veuve Clicquot, Champagner, eisgekühlt. Zum
                        Anstoßen gerne Glasweise, für Partytiger darf’s auch
                        gern Flaschenweise sein.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </Scene>

            <Scene duration={600} classToggle="in-view">
              <section className="content clearfix" id="cafe">
                <div className="wrapper clearfix mt4">
                  <div className="col md-col-7 mt3 px1 is-1-mobile">
                    <div className="headerbox mb2">
                      <h2>Und am Ende ergibt alles einen Gin.</h2>
                    </div>
                    <div className="textbox">
                      <p>
                        Neben internationalen Gin-Klassikern kommen auch Fans
                        regionaler Gin Produzenten auf ihre Kosten. Schon mal
                        Gin aus Graz probiert? Einer unserer beliebtesten Gin
                        Sorten ist der schon mehrfach ausgezeichnete{" "}
                        <a
                          href="https://www.rooms-distillery.at/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Room’s 43
                        </a>
                        , der mit viel Liebe vom Grazer Alex Zimmer ins Leben
                        gerufen wurde. Ein weiterer Favorit in unserem Gin
                        Sortiment ist der Amuerte Gin, der aus Cocablättern
                        produziert wird.
                      </p>
                    </div>
                  </div>
                  <div className="col md-col-5 px1 is-2-mobile">
                    <Image
                      src={imageGin2}
                      alt="Kaffee und Topfenstrudel im Schäffners"
                      className="objectFit mtn4 mr2 z2 is-hidden-mobile"
                      style={{ height: 400 }}
                    />
                    {/*<Image*/}
                    {/*  src={hero3Image2}*/}
                    {/*  alt="Frühstück mit Freunden im Schäffners"*/}
                    {/*  className="tl4 mtn20 z4 objectFit is-hidden-mobile"*/}
                    {/*/>*/}
                    <Image
                      src={imageGin3}
                      alt="Frühstücksangebot, Brunchen im Schäffners"
                      className="z3 mtn3 tl1 objectFit is-hidden-mobile"
                      style={{ height: 250 }}
                    />
                  </div>
                </div>
              </section>
            </Scene>

            <div style={{ textAlign: "center" }}>
              <Image
                src={imageRum}
                alt="Flirten im Gastgarten am Tummelplatz"
                className="objectFit z3 mb2 is-hidden-desktop"
              />
            </div>

            <Scene duration={600} classToggle="in-view">
              <section className="content clearfix">
                <div className="wrapper clearfix">
                  <div className="col md-col-5 px1 is-2-mobile">
                    <Image
                      src={imageRum}
                      alt="Barbereich im Schäffners"
                      className="objectFit z1 mtn2 is-hidden-mobile"
                      style={{ width: 200 }}
                    />

                    <Image
                      src={hero2Image2}
                      alt="Schinken und Wurstspezialitäten mit Bier"
                      className="tr1 mtn5 z2 objectFit is-hidden-mobile"
                    />
                    {/*<Image*/}
                    {/*  src={hero2Image3}*/}
                    {/*  alt="Pikantes Croissant mit Orangensaft zum Frühstück im Schäffners"*/}
                    {/*  className="z3 mtn15 objectFit is-hidden-mobile"*/}
                    {/*/>*/}
                  </div>
                  <div className="col md-col-7 mt3 mb4 px1 is-1-mobile">
                    <div className="headerbox mb2">
                      <h2>Rum ist nicht gleich Rum</h2>
                    </div>

                    <div className="textbox">
                      <p>
                        Unsere Rumempfehlung ist keine geringere als{" "}
                        <i>A.H. Rise</i>
                      </p>
                      <p>
                        In welchem Fass der Rum gereift wird ist entscheidend
                        für seine ultimative Qualität. Einflussnehmend sind der
                        Inhalt der davor in dem Fass war und die Art/Qualität
                        des Holzes. Um den einzigartigen Geschmack von A.H. Rise
                        zu entfachen lagert dieser viele Jahre in
                        Süßholzfässern, bevor er es an unsere Bar schafft.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </Scene>

            <Scene duration={600} classToggle="in-view">
              <section className="content clearfix eatanddrink is-hidden-desktop">
                <div className="wrapper clearfix">
                  <div className="col is-1-mobile">
                    <Image
                      src={imageKarte}
                      alt="Frühstücksangebot, Brunchen im Schäffners"
                      className="objectFit mt4 z3"
                    />
                  </div>
                  <div className="col col-12 md-col-7 mb3 px1 is-2-mobile">
                    <div className="headerbox mb2 mt3">
                      <h2>Die Karte</h2>
                    </div>
                    <div className="textbox">
                      <p>Gibts hier in voller Pracht anzusehen</p>
                      <a href="Karte.pdf" target="_blank" className="button">
                        Zur Karte
                      </a>
                    </div>
                  </div>
                </div>
              </section>
            </Scene>

            <Scene duration={600} classToggle="in-view">
              <section className="content clearfix eatanddrink is-hidden-mobile">
                <div className="wrapper clearfix">
                  <div className="col md-col-5" />
                  <div className="col md-col-7 mt4 mb3 px1 is-1-mobile">
                    <div className="headerbox mb2 mt3 center">
                      <h2>Die Karte</h2>
                      <p>Gibts hier in voller Pracht anzusehen</p>
                      <a href="Karte.pdf" target="_blank" className="button">
                        Zur Karte
                      </a>
                    </div>
                  </div>
                </div>
              </section>
            </Scene>

            <Footer />
          </Controller>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default Drinks;
